import React from 'react';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';

import './Attributions.css';

export default function Finals() {
    return (
        <Typography align="center" className="attribution-wrapper">
            I am a terrible artist and this site would have a lot less color without all of these amazing badge icons
            that were 100% free:
            <br></br>
            <br></br>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Link href="https://www.flaticon.com/free-icons/dinosaur" title="dinosaur icons">
                        Dinosaur icons created by Freepik - Flaticon
                    </Link>
                    <Link href="https://www.flaticon.com/free-icons/tortilla" title="tortilla icons">
                        Tortilla icons created by Smashicons - Flaticon
                    </Link>
                    <Link href="https://www.flaticon.com/free-icons/chocolate" title="chocolate icons">
                        Chocolate icons created by photo3idea_studio - Flaticon
                    </Link>
                    <Link href="https://www.flaticon.com/free-icons/monster" title="monster icons">
                        Monster icons created by Freepik - Flaticon
                    </Link>
                    <Link href="https://www.flaticon.com/free-icons/marvel" title="marvel icons">
                        Marvel icons created by Freepik - Flaticon
                    </Link>
                    <Link href="https://www.flaticon.com/free-icons/portal" title="portal icons">
                        Portal icons created by Freepik - Flaticon
                    </Link>
                    <Link href="https://www.iconfinder.com/sensibleworld" title="Mando">
                        Helmet icon created by Symbolicons
                    </Link>
                    <Link href="https://www.iconfinder.com/vectto" title="Groot">
                        Groot icon created by Western Venture Partners LLC
                    </Link>
                    <Link href="https://www.flaticon.com/free-icons/force-field" title="force field icons">
                        Force field icons created by Freepik - Flaticon
                    </Link>
                    <Link href="https://www.flaticon.com/free-icons/trash" title="trash icons">
                        Trash icons created by Freepik - Flaticon
                    </Link>
                    <Link href="https://www.flaticon.com/free-icons/frankenstein" title="frankenstein icons">
                        Frankenstein icons created by Chanut-is-Industries - Flaticon
                    </Link>
                    <Link href="https://www.flaticon.com/free-icons/ramp" title="ramp icons">
                        Ramp icons created by Freepik - Flaticon
                    </Link>
                    <Link href="https://www.flaticon.com/free-icons/egg" title="egg icons">
                        Egg icons created by berkahicon - Flaticon
                    </Link>
                    <Link href="https://www.flaticon.com/free-icons/caucasian" title="caucasian icons">
                        Caucasian icons created by Freepik - Flaticon
                    </Link>
                    <Link href="https://www.flaticon.com/free-icons/easy" title="easy icons">
                        Easy icons created by Freepik - Flaticon
                    </Link>
                    <Link href="https://www.flaticon.com/free-icons/trophy" title="trophy icons">
                        Trophy icons created by Freepik - Flaticon
                    </Link>
                    <Link href="https://www.flaticon.com/free-icons/trophy" title="trophy icons">
                        Trophy icons created by Freepik - Flaticon
                    </Link>
                    <Link href="https://www.flaticon.com/free-icons/ok" title="ok icons">
                        Ok icons created by Flat Icons - Flaticon
                    </Link>
                    <Link href="https://www.flaticon.com/free-icons/correct" title="correct icons">
                        Correct icons created by Freepik - Flaticon
                    </Link>
                    <Link href="https://www.flaticon.com/free-icons/wizard" title="wizard icons">
                        Wizard icons created by Freepik - Flaticon
                    </Link>
                    <Link href="https://www.flaticon.com/free-icons/winner" title="winner icons">
                        Winner icons created by Freepik - Flaticon
                    </Link>
                    <Link href="https://www.flaticon.com/free-icons/angry" title="angry icons">
                        Angry icons created by justicon - Flaticon
                    </Link>
                    <Link href="https://www.flaticon.com/free-icons/magic" title="magic icons">
                        Magic icons created by Freepik - Flaticon
                    </Link>
                    <Link href="https://www.flaticon.com/free-icons/double" title="double icons">
                        Double icons created by Smashicons - Flaticon
                    </Link>
                    <Link href="https://www.flaticon.com/free-icons/shield" title="shield icons">
                        Shield icons created by Freepik - Flaticon
                    </Link>
                    <Link href="https://www.flaticon.com/free-icons/pipe" title="pipe icons">
                        Pipe icons created by Freepik - Flaticon
                    </Link>
                    <Link href="https://www.flaticon.com/free-icons/cartoon" title="cartoon icons">
                        Cartoon icons created by surang - Flaticon
                    </Link>
                    <Link href="https://www.flaticon.com/free-icons/virus" title="virus icons">
                        Virus icons created by Freepik - Flaticon
                    </Link>
                    <Link href="https://www.flaticon.com/free-icons/spring-swing-rocket">
                        Spring swing rocket icons created by Freepik - Flaticon
                    </Link>
                    <Link href="https://www.flaticon.com/free-icons/fork" title="fork icons">
                        Fork icons created by Cuputo - Flaticon
                    </Link>
                    <Link href="https://www.flaticon.com/free-icons/cherries" title="cherries icons">
                        Cherries icons created by Smashicons - Flaticon
                    </Link>
                    <Link href="https://www.flaticon.com/free-icons/business-and-finance">
                        Business and finance icons created by Uniconlabs - Flaticon
                    </Link>
                    <Link href="https://www.flaticon.com/free-icons/action" title="action icons">
                        Action icons created by max.icons - Flaticon
                    </Link>
                    <Link href="https://www.flaticon.com/free-icons/broken-heart" title="broken heart icons">
                        Broken heart icons created by Freepik - Flaticon
                    </Link>
                    <Link href="https://www.flaticon.com/free-icons/scoreboard" title="scoreboard icons">
                        Scoreboard icons created by Freepik - Flaticon
                    </Link>
                    <Link href="https://www.flaticon.com/free-icons/bone" title="bone icons">
                        Bone icons created by Freepik - Flaticon
                    </Link>
                    <Link
                        href="https://www.flaticon.com/free-icons/sports-and-competition"
                        title="sports and competition icons"
                    >
                        Sports and competition icons created by Freepik - Flaticon
                    </Link>
                    <Link href="https://www.flaticon.com/free-icons/champion" title="champion icons">
                        Champion icons created by Freepik - Flaticon
                    </Link>
                    <Link href="https://www.flaticon.com/free-icons/superhero" title="superhero icons">
                        Superhero icons created by Freepik - Flaticon
                    </Link>
                    <Link href="https://www.flaticon.com/free-icons/spiderman" title="Spiderman icons">
                        Spiderman icons created by Hopstarter - Flaticon
                    </Link>
                    <Link href="https://www.flaticon.com/free-icons/child" title="child icons">
                        Child icons created by Freepik - Flaticon
                    </Link>
                    <Link href="https://www.flaticon.com/free-icons/zero" title="zero icons">
                        Zero icons created by Freepik - Flaticon
                    </Link>
                    <Link href="https://www.flaticon.com/free-icons/medal" title="medal icons">
                        Medal icons created by Freepik - Flaticon
                    </Link>
                    <Link href="https://www.flaticon.com/free-icons/anniversary" title="anniversary icons">
                        Anniversary icons created by Freepik - Flaticon
                    </Link>
                    <Link href="https://www.flaticon.com/free-icons/ribbon" title="ribbon icons">
                        Ribbon icons created by Freepik - Flaticon
                    </Link>
                    <Link href="https://www.flaticon.com/free-icons/coin" title="coin icons">
                        Coin icons created by Freepik - Flaticon
                    </Link>
                    <Link href="https://www.flaticon.com/free-icons/ball-one" title="ball one icons">
                        Ball one icons created by Boris farias - Flaticon
                    </Link>
                    <Link href="https://www.flaticon.com/free-icons/hunter" title="hunter icons">
                        Hunter icons created by max.icons - Flaticon
                    </Link>
                    <Link href="https://www.flaticon.com/free-icons/sniper-gun" title="sniper gun icons">
                        Sniper gun icons created by Freepik - Flaticon
                    </Link>
                    <Link href="https://www.flaticon.com/free-icons/magneto" title="magneto icons">Magneto icons created by pocike - Flaticon</Link>
                    <Link href="https://www.flaticon.com/free-icons/sad" title="sad icons">Sad icons created by Freepik - Flaticon</Link>
                </Grid>
                <Grid item xs={6}>
                    <Link href="https://www.flaticon.com/free-icons/spaceship" title="spaceship icons">
                        Spaceship icons created by Pixel Buddha - Flaticon
                    </Link>
                    <Link href="https://www.flaticon.com/free-icons/knight" title="knight icons">
                        Knight icons created by Freepik - Flaticon
                    </Link>
                    <Link href="https://www.flaticon.com/free-icons/deal" title="deal icons">
                        Deal icons created by Freepik - Flaticon
                    </Link>
                    <Link href="https://www.flaticon.com/free-icons/goose" title="goose icons">
                        Goose icons created by smalllikeart - Flaticon
                    </Link>
                    <Link href="https://www.flaticon.com/free-icons/door" title="door icons">
                        Door icons created by Freepik - Flaticon
                    </Link>
                    <Link href="https://www.flaticon.com/free-icons/cry" title="cry icons">
                        Cry icons created by Freepik - Flaticon
                    </Link>
                    <Link href="https://www.flaticon.com/free-icons/girl" title="girl icons">
                        Girl icons created by Smashicons - Flaticon
                    </Link>
                    <Link href="https://www.flaticon.com/free-icons/hotdog" title="hotdog icons">
                        Hotdog icons created by BZZRINCANTATION - Flaticon
                    </Link>
                    <Link href="https://www.flaticon.com/free-icons/poop" title="poop icons">
                        Poop icons created by Freepik - Flaticon
                    </Link>
                    <Link href="https://www.iconfinder.com/vectto" title="Deadpool">
                        Deadpool icon created by Western Venture Partners LLC
                    </Link>
                    <Link href="https://www.flaticon.com/free-icons/factory" title="factory icons">
                        Factory icons created by Freepik - Flaticon
                    </Link>
                    <Link href="https://www.flaticon.com/free-icons/identity" title="identity icons">
                        Identity icons created by Freepik - Flaticon
                    </Link>
                    <Link href="https://www.flaticon.com/free-icons/eagle" title="eagle icons">
                        Eagle icons created by Freepik - Flaticon
                    </Link>
                    <Link href="https://www.flaticon.com/free-icons/heat" title="heat icons">
                        Heat icons created by Freepik - Flaticon
                    </Link>
                    <Link href="https://www.flaticon.com/free-icons/danger" title="danger icons">
                        Danger icons created by Freepik - Flaticon
                    </Link>
                    <Link href="https://www.flaticon.com/free-icons/sheriff" title="sheriff icons">
                        Sheriff icons created by Nikita Golubev - Flaticon
                    </Link>
                    <Link href="https://www.flaticon.com/free-icons/10" title="10 icons">
                        10 icons created by Freepik - Flaticon
                    </Link>
                    <Link href="https://www.flaticon.com/free-icons/arrow-up" title="arrow up icons">
                        Arrow up icons created by lutfix - Flaticon
                    </Link>
                    <Link href="https://www.flaticon.com/free-icons/all-included" title="all included icons">
                        All included icons created by kerismaker - Flaticon
                    </Link>
                    <Link href="https://www.flaticon.com/free-icons/open" title="open icons">
                        Open icons created by Good Ware - Flaticon
                    </Link>
                    <Link href="https://www.flaticon.com/free-icons/robot" title="robot icons">
                        Robot icons created by Nhor Phai - Flaticon
                    </Link>
                    <Link href="https://www.flaticon.com/free-icons/mummy" title="mummy icons">
                        Mummy icons created by Freepik - Flaticon
                    </Link>
                    <Link href="https://www.flaticon.com/free-icons/original" title="original icons">
                        Original icons created by Freepik - Flaticon
                    </Link>
                    <Link href="https://www.flaticon.com/free-icons/light-saber" title="light saber icons">
                        Light saber icons created by Smashicons - Flaticon
                    </Link>
                    <Link href="https://www.flaticon.com/free-icons/tournament" title="tournament icons">
                        Tournament icons created by Freepik - Flaticon
                    </Link>
                    <Link href="https://www.flaticon.com/free-icons/pizza" title="pizza icons">
                        Pizza icons created by Freepik - Flaticon
                    </Link>
                    <Link href="https://www.flaticon.com/free-icons/hat" title="hat icons">
                        Hat icons created by Freepik - Flaticon
                    </Link>
                    <Link href="https://www.flaticon.com/free-icons/bridesmaid" title="bridesmaid icons">
                        Bridesmaid icons created by Nathania Desmonda Tjipto - Flaticon
                    </Link>
                    <Link href="https://www.flaticon.com/free-icons/usa" title="usa icons">
                        Usa icons created by Freepik - Flaticon
                    </Link>
                    <Link href="https://www.flaticon.com/free-icons/horror" title="horror icons">
                        Horror icons created by Freepik - Flaticon
                    </Link>
                    <Link href="https://www.flaticon.com/free-icons/survival" title="survival icons">
                        Survival icons created by Freepik - Flaticon
                    </Link>
                    <Link href="https://www.flaticon.com/free-icons/cocktail" title="cocktail icons">
                        Cocktail icons created by Vitaly Gorbachev - Flaticon
                    </Link>
                    <Link href="https://www.flaticon.com/free-icons/sad" title="sad icons">
                        Sad icons created by juicy_fish - Flaticon
                    </Link>
                    <Link href="https://www.flaticon.com/free-icons/ring" title="ring icons">
                        Ring icons created by Roundicons - Flaticon
                    </Link>
                    <Link href="https://www.flaticon.com/free-icons/wrestling" title="wrestling icons">
                        Wrestling icons created by Febrian Hidayat - Flaticon
                    </Link>
                    <Link
                        href="https://www.flaticon.com/free-icons/sports-and-competition"
                        title="sports and competition icons"
                    >
                        Sports and competition icons created by Freepik - Flaticon
                    </Link>
                    <Link href="https://www.flaticon.com/free-icons/high-five" title="high five icons">
                        High five icons created by Freepik - Flaticon
                    </Link>
                    <Link href="https://www.flaticon.com/free-icons/doghouse" title="Doghouse icons">
                        Doghouse icons created by Freepik - Flaticon
                    </Link>
                    <Link href="https://www.flaticon.com/free-icons/venomous" title="venomous icons">
                        Venomous icons created by Freepik - Flaticon
                    </Link>
                    <Link
                        href="https://www.flaticon.com/free-icons/business-and-finance"
                        title="business and finance icons"
                    >
                        Business and finance icons created by vectorsmarket15 - Flaticon
                    </Link>
                    <Link href="https://www.flaticon.com/free-icons/shark" title="shark icons">
                        Shark icons created by Freepik - Flaticon
                    </Link>
                    <Link href="https://www.flaticon.com/free-icons/astronaut" title="astronaut icons">
                        Astronaut icons created by Eucalyp - Flaticon
                    </Link>
                    <Link href="https://www.flaticon.com/free-icons/olympic-games" title="olympic games icons">
                        Olympic games icons created by pongsakornRed - Flaticon
                    </Link>
                    <Link href="https://www.flaticon.com/free-icons/goodbye" title="Goodbye icons">
                        Goodbye icons created by Freepik - Flaticon
                    </Link>
                    <Link href="https://www.flaticon.com/free-icons/brick" title="brick icons">
                        Brick icons created by Freepik - Flaticon
                    </Link>
                    <Link href="https://www.flaticon.com/free-icons/scale" title="scale icons">
                        Scale icons created by monkik - Flaticon
                    </Link>
                    <Link href="https://www.flaticon.com/free-icons/hunting" title="hunting icons">
                        Hunting icons created by Freepik - Flaticon
                    </Link>
                    <Link href="https://www.flaticon.com/free-icons/trophy" title="trophy icons">
                        Trophy icons created by pmicon - Flaticon
                    </Link>
                    <Link href="https://www.flaticon.com/free-icons/crank" title="crank icons">Crank icons created by Izwar Muis - Flaticon</Link>
                    <Link href="https://www.flaticon.com/free-icons/pandoras-box" title="pandoras box icons">Pandoras box icons created by Freepik - Flaticon</Link>
                </Grid>
            </Grid>
        </Typography>
    );
}
